<template>
  <div id="app" :class="appClass">
    <a href="#main-content" class="skip-link">Jump to main content</a>

    <SiteHeader />
    <hero
      :block="hero"
      :breadcrumb="breadcrumb"
      :heroImage="heroImage"
      :simplePageTitle="heroSimplePageTitle"
    ></hero>
    <router-view id="main-content" @updateHero="updateHero" />
    <SiteFooter />
  </div>
</template>

<script>
import Hero from './components/Hero.vue';
import SiteHeader from './components/SiteHeader.vue';
import SiteFooter from './components/SiteFooter.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    Hero,
    SiteFooter,
  },
  computed: {
    appClass() {
      const classList = [this.$route.name];
      if (this.$route.meta.type) {
        classList.push(`post-type-${this.$route.meta.type}`);
      }
      if (this.$route.meta.template) {
        classList.push(this.$route.meta.template);
      }
      if (this.$route.meta.dark_footer) {
        classList.push('dark-footer');
      }

      if (window.prerenderSettings) {
        classList.push('prerendered');
      }
      return classList.join(' ');
    },
  },
  data() {
    return {
      hero: {},
      heroImage: {},
      heroSimplePageTitle: '',
      breadcrumb: [],
    };
  },
  methods: {
    updateHero(blocks, breadcrumb = [], heroImage) {
      // console.log('updateHero', blocks, breadcrumb, heroImage)
      this.breadcrumb = breadcrumb;
      this.heroImage = heroImage;
      if (typeof blocks === 'string') {
        this.heroSimplePageTitle = blocks;
        this.hero = {};
      } else {
        this.heroSimplePageTitle = '';
        this.hero = blocks;
      }
      this.$forceUpdate();
    },
  },
  metaInfo: {
    title: 'File & Claim',
    titleTemplate: '%s : File & Claim',
  },
};
</script>

<style lang="scss" src="./styles/app.scss"></style>
