export const blockMixin = {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parsedBlocks() {
      if (!this.block) {
        return [];
      }
      return this.block.innerBlocks;
    },
    dynamicContent() {
      return {
        functional: true,
        render: (h) => {
          if (!this.parsedBlocks) {
            return;
          }
          return this.parsedBlocks.map((block) =>
            this.getInnerBlocks(h, block)
          );
        },
      };
    },
  },
  methods: {
    getButtons(h, block) {
      return h(
        'div',
        { class: 'wp-block-buttons' },
        block.innerBlocks.map((button) => {
          const href = button.innerHTML.match(/href="([^"]*)"/)[1];
          var div = document.createElement('div');
          div.innerHTML = button.innerHTML;
          const buttonText = div.textContent || div.innerText || '';
          return h(
            'router-link',
            { attrs: { to: href, class: 'button' } },
            buttonText
          );
        })
      );
    },
    getMediaText(h, block) {
      return h(
        'div',
        {
          class: block.attrs.className,
        },
        [
          h(
            'div',
            {
              class: this.getClasses(block),
            },
            [
              h(
                'div',
                {
                  class: 'wp-block-media-text__content',
                  style: { minHeight: '200px' },
                },
                block.innerBlocks.map((b) => this.getInnerBlocks(h, b))
              ),
              h(
                'figure',
                {
                  class: 'wp-block-media-text__media',
                },
                [
                  h('DisplayImage', {
                    props: { attachmentId: block.attrs.mediaId },
                  }),
                ]
              ),
            ]
          ),
        ]
      );
    },
    getGroup(h, block) {
      return h('div', { class: this.getClasses(block) }, [
        h(
          'div',
          { class: 'wp-block-group__inner-container' },
          block.innerBlocks.map((b) => this.getInnerBlocks(h, b))
        ),
      ]);
    },
    getTagName(block) {
      if (!block.innerContent || !block.innerContent[0]) {
        return 'span';
      }
      const match = block.innerContent[0].match(/<(\w+)/);
      return match ? match[1] : 'span';
    },
    getClasses(block) {
      if (!block.innerContent || !block.innerContent[0]) {
        return '';
      }
      const trimmedContent = block.innerContent[0].trim();
      const firstTagMatch = trimmedContent.match(
        /^<\w+\s+[^>]*class="([^"]*)"/
      );
      const match = firstTagMatch ? firstTagMatch : null;
      return match ? match[1] : '';
    },
    unwrapInnerContent(htmlString) {
      const div = document.createElement('div');
      div.innerHTML = htmlString;
      return Array.from(div.childNodes);
    },
    blockParser(block, h, node) {
      return node;
    },
    getInnerBlocks(h, block) {
      if (block.blockName == 'core/buttons') {
        return this.getButtons(h, block);
      }
      if (block.blockName == 'core/media-text') {
        return this.getMediaText(h, block);
      }
      if (block.blockName == 'core/group') {
        return this.getGroup(h, block);
      }
      const children =
        block.innerBlocks.length > 0
          ? block.innerBlocks.map((innerBlock) =>
              this.getInnerBlocks(h, innerBlock)
            )
          : this.unwrapInnerContent(block.innerHTML).map((node) => {
              if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent;
              } else {
                return h('span', { domProps: { innerHTML: node.innerHTML } });
              }
            });

      const attrs = {};
      const classes = this.getClasses(block);
      if (classes) {
        attrs.class = classes;
      }
      return this.blockParser(
        block,
        h,
        h(this.getTagName(block), attrs, children)
      );
    },
  },
};
