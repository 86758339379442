<template>
  <transition name="fade">
    <img
      :src="image.source_url"
      :alt="altText"
      :width="width"
      :height="height"
      @load="onLoad"
      v-if="image"
    />
  </transition>
</template>

<script>
export default {
  name: 'DisplayImage',
  props: {
    attachmentId: {
      type: Number,
    },
    altText: {
      type: String,
      default: '',
    },
    '2x': {
      type: Boolean,
      default: false,
    },
    imgObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image: false,
      imageLoaded: false,
    };
  },
  computed: {
    width() {
      if (this.imgObj && this.imgObj.width) {
        return this.imgObj.width;
      }
      if (!this.image.media_details) {
        return 0;
      }
      return this['2x']
        ? this.image.media_details.width / 2
        : this.image.media_details.width;
    },
    height() {
      if (this.imgObj && this.imgObj.height) {
        return this.imgObj.height;
      }
      if (!this.image.media_details) {
        return 0;
      }
      return this['2x']
        ? this.image.media_details.height / 2
        : this.image.media_details.height;
    },
  },
  watch: {
    imgObj: {
      handler: function (to) {
        if (to && to.source_url) {
          this.image = to;
        }
      },
      deep: true,
    },
    attachmentId: {
      handler: function (to) {
        if (to) {
          this.$store.dispatch('getImage', to).then(() => {
            this.image = this.$store.getters.getImage(to);
            this.$forceUpdate();
          });
        }
      },
    },
  },
  mounted() {
    if (!this.attachmentId && !this.imgObj) {
      return;
    }
    if (this.imgObj && this.imgObj.source_url) {
      this.image = this.imgObj;
      return;
    }
    this.$store.dispatch('getImage', this.attachmentId).then(() => {
      this.image = this.$store.getters.getImage(this.attachmentId);
      this.$forceUpdate();
    });
  },
  methods: {
    onLoad() {
      this.imageLoaded = true;
      this.$emit('loaded');
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
