<template>
  <section class="hero" v-if="!error" :class="{ 'transition-in': animate }">
    <div class="wrapper">
      <div class="hero--content">
        <Breadcrumb :items="breadcrumb" />
        <h1 v-if="simplePageTitle">{{ simplePageTitle }}</h1>
        <component :is="dynamicContent" v-else></component>
      </div>
      <div class="hero--image" v-if="blockExists && block.attrs.mediaId">
        <DisplayImage
          :attachmentId="block.attrs.mediaId"
          :altText="altText"
          :imgObj="heroImage"
          @loaded="imageLoaded = true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { blockMixin } from '@/mixins/blockMixin';
import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';

export default {
  name: 'PageHero',
  components: {
    Breadcrumb: BreadcrumbComponent,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    heroImage: {
      type: Object,
      default: () => {},
    },
    simplePageTitle: {
      type: String,
      default: '',
    },
  },
  mixins: [blockMixin],
  data() {
    return {
      error: false,
      animate: false,
      imageLoaded: false,
    };
  },
  watch: {
    block() {
      this.animate = false;
      this.imageLoaded = false;
      this.$nextTick(() => {
        if (this.noImage) {
          this.animate = true;
        }
        this.updateHeight();
      });
    },
    heroImage() {
      this.imageLoaded = false;
    },
    imageLoaded() {
      setTimeout(() => {
        this.animate = true;
      }, 500);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.noImage) {
        this.animate = true;
      }
      this.updateHeight();
    });

    window.addEventListener('resize', this.updateHeight);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.updateHeight);
    });
  },
  computed: {
    blockExists() {
      return this.block && this.block.blockName === 'core/media-text';
    },
    blockHasContents() {
      return (
        this.block &&
        this.block.innerBlocks &&
        this.block.innerBlocks.length > 0
      );
    },
    noImage() {
      if (this.blockExists) {
        return !this.block.attrs.mediaId;
      }
      return !this.heroImage;
    },
    altText() {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.block.innerHTML, 'text/html');
      const img = doc.querySelector('img');
      return img ? img.getAttribute('alt') : '';
    },
  },
  methods: {
    updateHeight() {
      const heroElement = this.$el;
      if (!heroElement || typeof heroElement.querySelector !== 'function') {
        return;
      }
      if (
        this.$route.name === 'archive-case-studies' ||
        this.$route.name === 'contact-us'
      ) {
        return (heroElement.style.height = 'calc(100vh - var(--header-size))');
      }
      if (!this.blockHasContents && !this.simplePageTitle) {
        heroElement.style.height = '0';
        return;
      }
      const innerElement = heroElement.querySelector('.wrapper');
      if (heroElement && innerElement) {
        heroElement.style.height = `${innerElement.offsetHeight}px`;
      }
    },
  },
};
</script>
