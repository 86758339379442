<template>
  <nav aria-label="breadcrumb" v-if="items">
    <ol class="breadcrumb">
      <li v-for="(crumb, index) in parsedItems" :key="index">
        <router-link v-if="crumb.name == 'Home'" :to="crumb.item">
          <inline-svg
            aria-label="Home"
            :src="require('@/assets/home.svg')"
            v-if="$Modernizr.svg"
          />
          <template v-else>Home</template>
        </router-link>
        <router-link v-else-if="crumb.item" :to="crumb.item">{{
          crumb.name
        }}</router-link>
        <span v-if="index < parsedItems.length - 1" class="divider"> | </span>
        <span aria-current="page" v-else>{{ crumb.name }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { faHome } from '@fortawesome/free-solid-svg-icons';
export default {
  name: 'BreadcrumbComponent',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      home: faHome,
    };
  },
  computed: {
    parsedItems() {
      return this.items.map((crumb) => {
        if (crumb.item) {
          crumb.item = crumb.item.replace(
            process.env.VUE_APP_URL ? process.env.VUE_APP_URL : '',
            ''
          );
        }
        return crumb;
      });
    },
  },
};
</script>
