<template>
  <footer class="contentinfo" role="contentinfo">
    <div class="wrapper">
      <router-link to="/" class="brand">
        <inline-svg
          aria-label="File & Claim"
          :src="require('@/assets/logo-fileandclaim.svg')"
          v-if="$Modernizr.svg"
        />
        <img
          :src="require('@/assets/logo-fileandclaim.png')"
          alt="File & Claim"
          v-else
        />
      </router-link>

      <nav role="navigation">
        <ul class="footer-menu">
          <li v-for="item in menu" :key="item.id">
            <router-link
              :to="item.url"
              :aria-current="item.url === $route.path ? 'page' : null"
              :class="item.classes"
              ><span v-html="item.title"></span
            ></router-link>
          </li>
        </ul>
      </nav>

      <div class="footer--content">
        <p v-html="footerContent"></p>

        <p>&copy; {{ new Date().getFullYear() }} All Rights Reserved</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$store.dispatch('getMenu', 'footer-menu').then(() => {
      if (!this.$store.getters.getMenu('footer-menu')) {
        return;
      }
      this.footerContent =
        this.$store.getters.getMenu('footer-menu').acf.footer_content;
      this.menu = this.$store.getters.getMenu('footer-menu').items;
    });
  },
  data() {
    return {
      menu: [],
      footerContent: '',
    };
  },
};
</script>
