import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import InlineSvg from 'vue-inline-svg';
import VueGtag from 'vue-gtag';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import DisplayImage from './components/DisplayImage.vue';
import modernizr from 'modernizr';
import App from './App.vue';

// global components
Vue.component('inline-svg', InlineSvg);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('DisplayImage', DisplayImage);

Vue.use(Vuex);
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Meta);

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_PROPERTY_ID,
  },
});

Vue.prototype.$Modernizr = modernizr;

async function createApp() {
  const routes = await import('./routes').then((module) => module.default);

  const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
      window.scrollTo(0, 0);
    },
  });

  new Vue({
    router,
    store,
    components: { App },
    template: '<App/>',
  }).$mount('#app');
}

createApp();
