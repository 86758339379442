import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_WORDPRESS_URL;

if (window.prerenderSettings) {
  axios.defaults.baseURL = window.prerenderSettings.axiosBaseURL;
}

const getters = {
  getMenu: (state) => (menu) => {
    return state.menus[menu];
  },
  getPages: (state) => {
    return Object.values(state.pages);
  },
  getPage: (state) => (slug) => {
    return state.pages[slug];
  },
  getImage: (state) => (id) => {
    return state.attachments[id];
  },
  getCaseStudies: (state) => {
    return Object.values(state.caseStudies);
  },
  getCaseStudy: (state) => (slug) => {
    return state.caseStudies[slug];
  },
  getGlobalOptions: (state) => {
    return Object.values(state.caseStudies);
  },
  getGlobalOption: (state) => (option) => {
    return state.globalOptions[option];
  },
  getError: (state) => {
    return state.error;
  },
};

const state = {
  error: false,
  pages: {},
  caseStudies: {},
  globalOptions: {},
  attachments: {},
  menus: {},
};

const actions = {
  async getMenu({ commit }, menu) {
    try {
      const response = await axios.get(`/wp-json/menus/v1/locations/${menu}`);
      commit('setMenu', { menu, data: response.data });
    } catch (error) {
      console.error(error);
    }
  },
  async getPages({ commit }) {
    try {
      const response = await axios.get('/wp-json/wp/v2/pages');
      commit('setPages', response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async getPage({ commit }, slug) {
    if (
      state.pages[slug] &&
      (state.pages[slug].content.raw || state.pages[slug].content_rawmod)
    ) {
      return true;
    }
    try {
      const response = await axios.get(
        slug == '/'
          ? `/wp-json/wp/v2/frontpage`
          : `/wp-json/wp/v2/pages?slug=${slug}`
      );
      const page = response.data.length == 1 ? response.data[0] : response.data;
      if (!page.slug) {
        page.slug = slug;
      }
      commit('setPage', page);
    } catch (error) {
      console.error(error);
    }
  },
  async getImage({ commit }, id) {
    if (state.attachments[id] && state.attachments[id].source_url) {
      return true;
    }
    try {
      const response = await axios.get(`/wp-json/wp/v2/media/${id}`);
      commit('setImage', response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async getCaseStudies({ commit }, setHead) {
    try {
      const response = await axios.get('/wp-json/wp/v2/case_study');
      commit('setCaseStudies', response.data);

      if (setHead) {
        const getHead = await axios.get('/wp-json/wp/v2/types/case_study');
        commit('setPageHead', getHead.data.yoast_head);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getCaseStudy({ commit }, slug) {
    if (
      state.caseStudies[slug] &&
      (state.caseStudies[slug].content.raw ||
        state.caseStudies[slug].content_rawmod)
    ) {
      return true;
    }
    try {
      const response = await axios.get(
        `/wp-json/wp/v2/case_study?slug=${slug}`
      );
      commit('setCaseStudy', response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async getGlobalOptions({ commit }) {
    try {
      const response = await axios.get(
        '/wp-json/acf/v3/options/headless-settings'
      );
      commit('setGlobalOptions', response.data.acf);
    } catch (error) {
      console.error(error);
    }
  },
  async submitForm({ commit }, form) {
    commit('clearError');

    // Verify reCAPTCHA token
    const recaptchaResponse = await axios
      .post(
        `https://recaptchaenterprise.googleapis.com/v1/projects/octopus-ink-1472231966450/assessments?key=${process.env.VUE_APP_RECAPTCHA_API_KEY}`,
        {
          event: {
            token: form.recaptchaToken,
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            expectedAction: 'submit',
          },
        }
      )
      .catch((e) => {
        commit('setError', 'reCAPTCHA verification failed');
        console.error('reCAPTCHA verification error:', e.response.data);
        return null;
      });

    if (!recaptchaResponse || !recaptchaResponse.data.tokenProperties.valid) {
      commit('setError', 'Invalid reCAPTCHA token');
      return false;
    }

    const response = await axios
      .post(
        `/wp-json/contact-form-7/v1/contact-forms/${form.formID}/feedback`,
        form.formData
      )
      .catch((e) => {
        state.error = e.response.data.message;
      });
    if (response) {
      return true;
    }
  },
};

const mutations = {
  setError(state, error) {
    state.error = error;
  },
  setMenu(state, { menu, data }) {
    data.items = data.items.map((item) => {
      if (item.object === 'page' || item.object === 'case_study') {
        // the menu just has relative URLs
        const url = new URL(item.url);
        item.url = url.pathname;
      }
      return item;
    });
    state.menus[menu] = data;
  },
  setPages(state, data) {
    data.forEach((page) => {
      state.pages[page.slug] = page;
    });
  },
  setPage(state, data) {
    if (Array.isArray(data)) {
      data.forEach((page) => {
        state.pages[page.slug] = page;
      });
    } else {
      state.pages[data.slug] = data;
    }
  },
  setImage(state, data) {
    state.attachments[data.id] = data;
  },
  setCaseStudies(state, data) {
    data.forEach((caseStudy) => {
      state.caseStudies[caseStudy.slug] = caseStudy;
    });
  },
  setCaseStudy(state, data) {
    state.caseStudies[data[0].slug] = data[0];
  },
  setGlobalOptions(state, data) {
    state.globalOptions = data;
  },
  clearError(state) {
    state.error = false;
  },
  setPageHead(state, data) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = data;
    const titleTag = tempDiv.querySelector('title');
    if (titleTag) {
      tempDiv.removeChild(titleTag);
    }
    while (tempDiv.firstChild) {
      document.head.appendChild(tempDiv.firstChild);
    }
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
