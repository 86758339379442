<template>
  <header class="banner" role="banner">
    <nav role="navigation" class="wrapper">
      <router-link to="/" class="brand">
        <inline-svg
          aria-label="File & Claim"
          :src="require('@/assets/logo-fileandclaim.svg')"
          v-if="$Modernizr.svg"
        />
        <img
          :src="require('@/assets/logo-fileandclaim.png')"
          alt="File & Claim"
          v-else
        />
      </router-link>

      <button
        @click="toggleMenu"
        class="menu-toggle"
        :aria-expanded="menuOpen ? true : false"
        aria-controls="main-menu"
      >
        <inline-svg
          :src="require('@/assets/menu.svg')"
          v-if="!menuOpen"
          width="32"
          height="32"
        />
        <inline-svg
          :src="require('@/assets/close.svg')"
          width="32"
          height="32"
          v-else
        />
      </button>

      <ul class="main-menu" :class="{ open: menuOpen }" id="main-menu">
        <li v-for="item in menu" :key="item.id">
          <router-link
            :to="item.url"
            :aria-current="item.url === $route.path ? 'page' : null"
            :class="item.classes"
            ><span v-html="item.title"></span
          ></router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$store.dispatch('getMenu', 'header-menu').then(() => {
      if (!this.$store.getters.getMenu('header-menu')) {
        return;
      }
      this.menu = this.$store.getters.getMenu('header-menu').items;
    });
  },
  watch: {
    $route() {
      this.menuOpen = false;
    },
  },
  data() {
    return {
      menuOpen: false,
      menu: [],
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
